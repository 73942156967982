// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
// todo: temp c-dark-theme plumbing for select option
.c-dark-theme option {
  background-color: #34343b;
  border: 1px solid #23282c;
}

.c-dark-theme input:-webkit-autofill {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.87) !important;
}

.c-dark-theme input:-webkit-autofill,
.c-dark-theme input:-webkit-autofill:hover,
.c-dark-theme input:-webkit-autofill:focus,
.c-dark-theme input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(47, 48, 58, 1) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #5b6873 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
}
