.pageList .card-body {
  border-left: 1px solid #ccc !important;
}

.pageList ol li {
  margin-bottom: 16px;
}
.templateSection {
  padding: 16px;

  box-shadow: 0 2px 5px rgb(0 0 0 / 0.1);

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  /* box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.45),
    inset -25px 0px 25px -25px rgba(0, 0, 0, 0.45); */
}
